import store from '@/store'

export default function useResources() {
  const loadResources = async () => {
    if (Object.keys(store.state.dataset).length) return

    store.commit('setStateProps', { prop: 'isLoading', value: true })

    await store.dispatch('auth/getMe')
    await store.dispatch('dataset/fetchDataset')
    await store.dispatch('schemas/getSchemas')

    store.commit('setStateProps', { prop: 'isLoading', value: false })
  }

  return {
    loadResources,
  }
}
