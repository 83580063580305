import { mediaUrl } from '@/utils/helpers'

export const hasElement = (arr, url) => {
  let result = false

  for (let index = 0; index < arr.length; index++) {
    const element = arr[index]
    if (element.indexOf(url) !== -1) {
      result = true
    }
  }

  return result
}

export const precacheBanners = (banners) => {
  banners.forEach((e) => {
    const url = mediaUrl(e.image)

    fetch(url) // делаем запрос, что бы сервис-воркер перехватил
  })
}
