import { stateEnum } from '@/constants'

/**
 * @param {FormData} formData
 * @returns {Object}
 */
export function formDataToObject(formData) {
  const formDataObj = {}
  formData.forEach((value, key) => (formDataObj[key] = value))
  return formDataObj
}
/**
 * @param {object} object
 * @returns {FormData}
 */
export function objectToFormData(object) {
  const fd = new FormData()
  for (const key in object) {
    fd.append(key, object[key])
  }
  return fd
}

/**
 * @param {Scheme} scheme
 * @param {SegmentDetail} payload
 */
export function mergeSchemeWithPayload(scheme, payload) {
  for (const key in scheme) {
    scheme[key].value = payload[key]
  }
}

/**
 * @param {RequestStateMethods} state
 * @returns {string}
 */
export const getStateName = (state) => {
  return stateEnum[state]
}

/**
 * @param {Date} date
 * @returns {string}
 */
export const getLocalDate = (date) => {
  return new Date(date).toLocaleDateString('ru')
}

/**
 * Return full path url
 * Локально, может работать не корректно
 * @param {string} url
 * @returns {string}
 */
export const mediaUrl = (url) => {
  if (!url.startsWith('/media/')) url = '/media/' + url
  return process.env.VUE_APP_MEDIA_URL + url
}

/**
 * Мутирует передаваемый обьект удаляя все "пустые" свойтва.
 * Удаляет [[ null, undefined, '' ]]
 * Удобно использовать для необязательных query параметров, когда пустые значения не должны передаваться в запрос
 * @param {object} obj
 * @param {string[]} forceRemove
 */
export const removeEmpty = (obj, forceRemove = ['default']) => {
  var value, key
  for (key in obj) {
    value = obj[key]
    if (value == null || value === '' || forceRemove.includes(value))
      delete obj[key]
  }
}

/**
 * @param {function} fn
 * @param {number} ms
 * @return {function} debounced fn
 */
export const debounce = (fn, ms) => {
  var timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(fn, ms, ...args)
  }
}
